<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Intitulé poste" />
          </div>

          <div class="d-flex justify-center">
            <WarningEditComponent />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Détail de l'intitulé poste</div>
              </v-row>
            </v-card-title>

            <!-- le métier rome -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">alias ROME</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="L'alias"
                    dense
                    v-model="alias"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- les grands domaines rome -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">grand domaine ROME</div>
                </v-col>
                <v-col cols="7" align-self="start">
                  <v-autocomplete
                    :items="romeHightDomains"
                    item-text="label"
                    v-model="selectedRomeHightDomains"
                    no-data-text="aucun grand domaine ROME"
                    return-object
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- La définition -->
            <v-card-text>  
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="start">
                  <div class="capitalize font-weight-regular">définition (mini-fiche de poste)</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-textarea
                    placeholder="La définition"
                    dense
                    v-model="definition"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- Accès emploi métier -->
            <v-card-text>  
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="start">
                  <div class="capitalize font-weight-regular">accès emploi métier</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-textarea
                    placeholder="Les prérequis poste"
                    dense
                    v-model="acces"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- Les compétences de base -->
            <v-card-text>  
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="start">
                  <div class="capitalize font-weight-regular">compétences de base</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-textarea
                    placeholder="Les compétences de base"
                    dense
                    v-model="basicSkills"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="ma-2 px-4 btn"
                color="primary"
                :disabled="!hasChanged"
                @click="save"
              >
                <div class="capitalize">
                  enregistrer
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <AlertNotSavedModifsComponent
      :show="showAlertQuit"
      @quit="onQuitAlert"
      @notquit="onNotQuitAlert"
    />

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import WarningEditComponent from "@/components/ui/WarningEditComponent.vue";

import { RomeHightDomainsService } from "@/service/dictionary/rome_hight_domains_service.js";
import { InternalJobsService } from "@/service/dictionary/internal_jobs_service.js";

import { areTheSame } from "@/tools/string_tool.js";

import * as exceptions from "@/service/exception_to_message.js";

export default {
  name: "EditJobTitle",
  components: {
    Workflow,
    TitleAndReturnComponent,
    WarningEditComponent,
    AlertNotSavedModifsComponent,
  },

  mixins: [
    WorkflowMixin,
    RoleMixin,
    TableViewEditWorkflowMixin,
    SnackBarMixin,
    AlertNotSavedModifsMixin,
  ],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /**le service d'accès au APIs. */
      serviceInternalJobs: null,
      serviceRomeHightDomains: null,

      /** l'identifiant à éditer */
      entityId: null,

      /**La donnée originale */
      source: null,

      /** le nom */
      alias: null,
      /** la définition */
      definition: null,
      /** les accès métier */
      acces: null,
      /** Les compétences de base */
      basicSkills: null,

      /** La liste des grands domaines ROME */
      romeHightDomains: null,
      /** Le grnd domain rome sélectionné */
      selectedRomeHightDomains: null,
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        // Chargement de l'entité
        let entity = await this.serviceInternalJobs.getById(
          this.entityId
        );
        this.source = JSON.parse(JSON.stringify(entity));

        // Chargement des grands domaines rome
        this.romeHightDomains = await this.serviceRomeHightDomains.getAll();

        this.init();

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**initialiser les données à partir de l'objet source */
    init() {
      this.alias = this.source.alias;
      this.definition = this.source.definition;
      this.acces = this.source.acces;
      this.basicSkills = this.source.basicSkills;

      this.selectedRomeHightDomains = this.romeHightDomains.find((r) => r.code == this.source.romeHightDomainCode);
    },

    async save() {
      // enregistrement 
      if (this.entityHasChanged) {
        try {
          let entity = JSON.parse(JSON.stringify(this.source));
          entity.alias = this.alias;
          entity.romeHightDomainCode = this.selectedRomeHightDomains.code;
          entity.definition = this.definition;
          entity.acces = this.acces;
          entity.basicSkills = this.basicSkills;

          await this.serviceInternalJobs.update(entity);
          
          this.disableAlertQuit();
          this.$router.go(-1);

        } catch (error) {
          console.error(error);
          this.addErrorToSnackbar(
            "enregistrement du point requis annonce : " +
              (exceptions.toMessage(error) || "problème technique")
          );
        }
      }    
    },
  },
  computed: {
    completed() {
      if (!this.alias) return false;

      if (!this.selectedRomeHightDomains) {
        return false;
      }

      return true;
    },
    hasChanged() {
      if (!this.source) return false;

      if (!this.completed) return false;

      let changed = false;

      if (this.entityHasChanged) {
        changed = true;
      }

      return changed;
    },
    /**retourne true si l'entité est modifiée */
    entityHasChanged() {
      let changed = false;

      if (!areTheSame(this.source.alias, this.alias)) {
        changed = true;
      }
      if (this.source.romeHightDomainCode != this.selectedRomeHightDomains.code) {
        changed = true;
      }
      if (!areTheSame(this.source.definition, this.definition)) {
        changed = true;
      }
      if (!areTheSame(this.source.acces, this.acces)) {
        changed = true;
      }
      if (!areTheSame(this.source.basicSkills, this.basicSkills)) {
        changed = true;
      }

      return changed;
    },
  },
  mounted() {
    //instanciation des services d'accès aux APIs
    this.serviceRomeHightDomains = new RomeHightDomainsService(this.$api.getRomeHightDomainsApi());
    this.serviceInternalJobs = new InternalJobsService(this.$api.getInternalJobsApi());


    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.entityId = this.$route.params.id;
    }

    this.load();
  },
};
</script>

<style>
</style>